:root {
  /* colors */
  --creme: #FBF2CF;
  --dark: #2C3639;
  --green: #87CEC6;
  --darkGrey: #2C3639;
  --darkBlue: #00337C;
  /* font-family */
  --menu: 'Dosis';
  --headers: 'pacifico';
  --contents: 'handlee'
}
body {
  margin: 0;
  padding: 0;
  width: 100vw;
  /* background-color: var(--creme); */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
nav {
  position: fixed;
  z-index: 1500;
  height: 11vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
footer {
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column; 
  background-color: var(--dark);
}
footer p {
  text-align: center;
  font-family: poppins;
  color: var(--creme);
}

/* /////////////////////////////////////// navbar*/
#menuIcon {
  margin-left: 1rem;
  color: var(--brown);
  font-size: 2.5rem;
}
#drawerMenu {padding: 0 0.5rem;}
#drawerMenu h3 {font-family: var(--menu);}

/*///////////////////////////////////////texts*/
p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}
h1 {font-family: var(--headers);}
h2, h3 {font-family: var(--contents);}

/*//////////////////////////////////////modal*/
#poster {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#poster img {object-fit: contain;}

/*//////////////////////////////////////////////welcome*/
#kindergymBeeAnimImg {height: 5rem;}
#welcomeGrass {position: relative;}
#welcomeGrass img {
  position: absolute;
  width: 100%;
  bottom: 0;
}

/*/////////////////////////////////////////////ourCentres*/
#ourCentresHeader {
  grid-area: 1 / 1 / 2 / 2;
  display: flex;
  justify-content: center;
  align-items: center;
}
#ourCentresImgsCont {
  grid-area: 2 / 1 / 3 / 2;
  display: flex;
  justify-content: center;
  align-items: center;
}
#ourCentresImgs h1 {
  font-family: 'Dosis';
  color: var(--creme);
}
#topLeft {grid-area: 1 / 1 / 3 / 3;}
#bottomCornerLeft {grid-area: 3 / 1 / 4 / 2;}
#textBottomLeft {grid-area: 3 / 2 / 4 / 3;}
#topMiddleRight {grid-area: 1 / 3 / 2 / 4;}
#textTopRight {grid-area: 1 / 4 / 2 / 5;}
#bottomRight {grid-area: 2 / 3 / 4 / 5;}

/*//////////////////////////////////////////////programmes*/
#programmes {
  position: relative;
  grid-template-columns: 100vw;
}
#programmes h1{
  position: absolute; 
  z-index: 1;
  margin: auto;
}
#programmesHalfCircleCont {
  display: flex;
  justify-content: center;
  grid-area: 1 / 1 / 3 / 2;
}
#halfCircle {
  position: absolute;
  top: 0;
  width: 80vw;
  background-color: var(--green); 
  border-bottom-left-radius: 40vw;  
  border-bottom-right-radius: 40vw; 
}
#programmeCards {
  position: absolute;
  z-index: 2;
  grid-area: 2 / 1 / 3 / 2;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
#programmeCards img {object-fit: cover;}
#programmeCards h4, h5 {
  font-family: var(--contents);
  margin: 0;
  padding: 0;
}
#programmeCards h6 {
  font-family: var(--menu);
  padding-bottom: 1rem;
}
#programmeCards .MuiCardContent-root {
  margin: 0;
  height: fit-content;
}

/*///////////////////////////////////////////contact us*/
#contactUs {grid-template-columns: 5vw 40vw 10vw 40vw 5vw;}
#contactUs h1 {
  grid-area: 1 / 1 / 2 / 6;
  text-align: center;
}
#contactUsForm {grid-area: 2 / 3 / 5 / 5;}
#contactUsForm div {
  height: 100%;
  background-color: var(--creme);
  border-radius: 0.5rem;
}
#contactUsForm form {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.5rem;
}
#contactUsForm label {
  text-align: right;
  padding-left: 10vw;
  font-family: 'Dosis';
}
#contactUsForm input, textarea {
  font-family: 'Dosis';
  outline: 1px solid var(--dark);
  border-radius: 0.25rem;
  padding: 0.5rem;
}
#contactUsForm input[type=submit] {
  color: var(--creme);
  background-color: var(--darkGrey);
}
#contactUsImg {grid-area: 3 / 2 / 6 / 4;}
#contactUsImg img {
  object-fit: cover;
  border-radius: 0.5rem;
}
/*////////////////////////////////////////addresses*/
#addresses {background-color: var(--dark);}
#addresses h3, p {
  color: var(--creme);
  padding-bottom: 0.5rem;
}
#addresses h3 {
  text-align: center;
  padding-top: 2vh;
}

/*////////////////////////////////////////signin*/
#signin {gap: 0.5rem;}
#signin h2 {font-family: 'Dosis';}

/*////////////////////////////////////////franchise*/
#franchise p:nth-child(1) {
  padding-bottom: 2rem;
  font-size: 1.2rem;
}
#franchise a {
  text-decoration: none;
  color: var(--darkBlue);
}
#franchise p {
  font-family: 'Dosis';
  color: var(--dark);
  text-align: center;
  margin: 0;
  padding: 0;
}

/*////////////////////////////////////////////////////////////*/
/*//////////////////////////////////////////////media queries*/
/*//////////////////////////////////////////////desktop-view*/
@media only screen and (min-width: 768px) {
  #navbarKindergymLogo {
    max-height: 10vh;
    padding-right: 1.5rem;
  }

  /*/////////////////////////////////////////////modal */
  #poster {
    height: 95vh;
    width: fit-content;
  }
  #poster img {
    height: 100%;
    width: auto;
  }

  /*//////////////////////////////////////////////welcome*/
  #welcome {
    grid-template-columns: 25vw 50vw 25vw;
    grid-template-rows: 25vh 50vh 25vh;
  }
  #welcomeText {
    position: relative;
    top: 20%;
    grid-area: 1 / 1 / 3 / 2;
    padding-left: 2rem;
  }
  #welcomeText h1 {font-size: 2.5rem;}
  #kindoAnim {
    grid-area: 1 / 2 / 4 / 3;
    position: relative;
  }
  #kindoAnim img {
    position: absolute;
    bottom: -2rem;
    height: 90vh;
  }
  #kindergymBeeAnim {grid-area: 2 / 3 / 3/ 4;}
  #welcomeCloud {
    grid-area: 1 / 2 / 2 / 4;
    display: flex;
    justify-content: flex-end;
    padding-right: 3rem; 
  }
  #cloud0, #cloud1, #cloud2 {
    position: absolute;
    width: 35vw;
  }
  #welcomeGrass {grid-area: 3 / 1 / 4 / 4;}
  
  /*//////////////////////////////////////why kindergym/about us*/
  #whyKindergym {
    grid-template-columns: 45vw 55vw;
    grid-template-rows: 85vh;
  }
  #whyKindergymImg {grid-area: 1 / 1 / 2 / 2;}
  #whyKindergym img {
    max-height: 75vh;
    max-width: 40vw; 
    border-radius: 1rem;
  }
  #whyKindergymTexts {
    grid-area: 1 / 2 / 2 / 3;
    padding: 0 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #whyKindergymTexts h1 {
    display: flex;
    font-size: 3rem; 
    align-self: flex-start;}
  #whyKindergymTexts h3 {
    font-size: 1rem;
    text-justify: inter-word;
    padding-bottom: 1rem;
  }

  /*//////////////////////////////why start early/registration*/
  #whyStartEarly {
    grid-template-columns: 50vw 50vw;
    grid-template-rows: 100vh 40vh;
  }
  #rainbowAndClouds {grid-area: 1 / 2 / 2 / 3;}
  #rainbowAndClouds img {
    width: 40vw;
    max-height: 70vh; 
  }
  #whyStartEarlyTexts {
    grid-area: 1 / 1 / 2 / 2;
    padding: 0 1rem;
  }
  #whyStartEarlyTexts .MuiButton-root {width: 10rem;}
  #whyStartEarly h1 {
    font-size: 3rem;
    padding-bottom: 1rem;
  }
  #whyStartEarly h3 {
    font-size: 1rem;
    padding-bottom: 1rem;
  }
  #grass {
    position: relative;
    grid-area: 2 / 1 / 3 / 3;
    overflow: hidden;
  }
  #grass img{
    position: absolute;
    width: 130vw;
    object-fit: cover;
    bottom: 0;
  }

  /*////////////////////////////////////////our facilities*/
  #ourFacilities {
    position: relative;
    grid-template-columns: 50vw 50vw;
    grid-template-rows: 12vh 60vh;
  }
  #ourFacilitiesTextsHeader {
    grid-area: 1 / 1 / 2 / 3;
    display: flex;
    justify-content: flex-start;
  }
  #ourFacilitiesTextsHeader h1 {
    padding-left: 1rem;
    font-size: 3rem;
  }
  #ourFacilitiesImg {grid-area: 2 / 1 / 3 / 2;}
  #ourFacilitiesImg img {
    max-width: 45vw;
    max-height: 55vh;
  }
  #ourFacilitiesTexts {
    position: absolute;
    grid-area: 1 / 2 / 3 / 3;
    padding: 0 1rem;
    height: 60vh;
    top: 40%;
    transform: translateY(-50);
  }
  #ourFacilitiesTexts h2 {
    font-size: 2.5rem;
    padding-bottom: 1rem;
  }
  #ourFacilitiesTexts h2 h3 {
    font-size: 1rem;
    padding-bottom: 1rem;
  }

  /*////////////////////////////////////////our teachers*/
  #ourTeachers {
    grid-template-columns: repeat(2, 50vw);
    grid-template-rows: 70vh;
  }
  #ourTeachersTexts {
    grid-area: 1 / 1 / 2 / 2;
    padding: 0 1rem;
  }
  #ourTeachersTexts h2 {font-size: 2.5rem;}
  #ourTeachersTexts h2{padding: 1rem 0;}
  #ourTeachersTexts h3{
    font-size: 1rem;
    padding-bottom: 1rem;
  }
  #ourTeachersKindo {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-area: 1 / 2 / 2 / 3;
  }
  #ourTeachersKindo img {
    max-height: 60vh;
    max-width: 45vw;
  }

  /*////////////////////////////////////////our centres*/
  #ourCentres {
    grid-template-columns: 100vw;
    grid-template-rows: 18vh 95vh;
  }
  #ourCentresHeader h1{font-size: 3rem;}
  #ourCentresImgs {
    grid-template-columns: repeat(4, 23vw);
    grid-template-rows: repeat(3, 30vh);
  }
  #textBottomLeft h1 {font-size: 2.5rem;}
  #textTopRight h1 {font-size: 2rem;}
  
  /*////////////////////////////////////////programmes*/
  #programmes {grid-template-rows: 30vh 70vh;}
  #halfCircle {height: 90vh;}
  #programmeCards {
    top: -10vh;
    gap: 0.5rem;
  }
  #programmeCards h4, h6 {font-size: 1.2rem;}
  #programmeCards h5 {font-size: 0.8rem;}
  #programmeCards img {height: 25vh;}
  #programmesHalfCircleCont h1 {font-size: 3rem;}
  #programmeCards .MuiCard-root {
    width: 19vw;
    height: 45vh;
    display: flex;
    justify-content: center;
  }
  #programmeCards .MuiCardContent-root {
    padding: 1rem;
    height: 20vh;
  }
  #programmeCards a {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /*////////////////////////////////////////contact us*/
  #contactUs {grid-template-rows: 15vh repeat(4, 25vh) 15vh;}
  #contactUs h1 {font-size: 3rem;}
  #contactUsForm form {
    padding-right: 2rem;
    padding-top: 2rem;
  }
  #contactUsForm input, textarea {width: 30vw;}
  #contactUsForm textarea {height: 18vh;}
  #contactUsImg img {
    width: 50vw;
    object-fit: cover;
  }
  
  /*////////////////////////////////////////addresses*/
  #addresses p:nth-child(1) {font-size: 1.25rem;}
  #addresses div {padding: 1rem;}

  #franchise h6 {
    font-size: 1rem;
  }
}


/*////////////////////////////////////////////////////////////*/
/*//////////////////////////////////////////////media queries*/
/*//////////////////////////////////////////////mobile-view*/
@media only screen and (max-width: 767px) {
  #navbarKindergymLogo {
    max-height: 5vh;
    padding-right: 1rem;
  }

  /*/////////////////////////////////////////////modal */
  #poster {
    height: 80vh;
    max-width: 90vw;
  }
  #poster img {
    height: 100%;
    width: auto;
  }

  /*/////////////////////////////////////////////modal */
  #poster {
    height: fit-content;
    max-width: 95vw;
  }
  #poster img {
    max-height: 90vh;
    max-width: 90vw;
  }

  /*//////////////////////////////////////////////welcome*/
  #welcome {
    grid-template-columns: 20vw 60vw 20vw;
    grid-template-rows: 10vh 35vh 15vh 30vh 10vh;
  }
  #welcomeText {grid-area: 2 / 2 / 3 / 3;}
  #welcomeText h1 {font-size: 2rem;}
  #welcomeText h3 {font-size: 0.8rem;}
  #kindoAnim {
    grid-area: 4 / 2 / 6 / 3;
    position: relative;
  }
  #kindoAnim img {
    position: absolute;
    bottom: -1rem;
    height: 35vh;
  }
  #kindergymBeeAnim {grid-area: 1 / 3 / 3 / 4;}
  #welcomeCloud {
    grid-area: 3 / 1 / 4 / 4;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #cloud0, #cloud1, #cloud2 {
    position: absolute;
    right: 1rem;
    width: 80vw;
  }
  #welcomeGrass {grid-area: 5 / 1 / 6 / 4;}

  /*//////////////////////////////////////why kindergym/about us*/
  #whyKindergym {
    grid-template-columns: 100vw;
    grid-template-rows: 50vh 35vh;
  }
  #whyKindergymImg {grid-area: 1 / 1 / 2 / 2;}
  #whyKindergym img {
    width: 90vw; 
    border-radius: 1rem;
  }
  #whyKindergymTexts {
    grid-area: 2 / 1 / 3 / 2;
    padding: 0 1rem;
  }
  #whyKindergymTexts h3 {
    font-size: 0.8rem;
    text-justify: inter-word;
    padding-bottom: 1rem;
  }

  /*//////////////////////////////why start early/registration*/
  #whyStartEarly {
    grid-template-columns: 100vw;
    grid-template-rows: 40vh 50vh 20vh;
  }
  #rainbowAndClouds {grid-area: 1 / 1 / 2 / 2;}
  #rainbowAndClouds img {
    max-height: 60vh;
    width: 80vw;
  }
  #whyStartEarlyTexts {
    grid-area: 2 / 1 / 3 / 2;
    padding: 0 1rem;
  }
  #whyStartEarly h3 {
    font-size: 0.8rem;
    padding-bottom: 1rem;
  }
  #grass {
    grid-area: 3 / 1 / 4 / 2;
    overflow: hidden;
  }
  #grass img{
    object-fit: cover;
    position: relative;
    left: -50vw;
    bottom: 0;
  }

  /*////////////////////////////////////////our facilities*/
  #ourFacilities {
    position: relative;
    grid-template-columns: 100vw;
    grid-template-rows: 10vh 50vh 40vh;
  }
  #ourFacilitiesTextsHeader {
    grid-area: 1 / 1 / 2 / 2;
    display: flex;
    justify-content: center;
  }
  #ourFacilitiesTextsHeader h1 {
    position: absolute;
    top: 5%;
  }
  #ourFacilitiesImg {grid-area: 2 / 1 / 3 / 2;}
  #ourFacilitiesImg img {
    max-width: 90vw;
    max-height: 40vh;
  }
  #ourFacilitiesTexts {
    grid-area: 3 / 1 / 4 / 2;
    padding: 0 1rem;
  }
  #ourFacilitiesTexts h2 {padding-bottom: 1rem;}
  #ourFacilitiesTexts h3 {
    font-size: 1rem;
    padding-bottom: 1rem;
  }

  /*////////////////////////////////////////our teachers*/
  #ourTeachers {
    grid-template-columns: 100vw;
    grid-template-rows: 60vh 40vh;
  }
  #ourTeachersTexts {
    grid-area: 2 / 1 / 3 / 2;
    padding: 0 1rem;
  }
  #ourTeachersTexts h2{padding: 1rem 0;}
  #ourTeachersTexts h3{
    font-size: 1rem;
    padding-bottom: 1rem;
  }
  #ourTeachersKindo {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    grid-area: 1 / 1 / 2 / 2;
  }
  #ourTeachersKindo img {
    max-height: 55vh;
    max-width: 90vw;
  }

  /*////////////////////////////////////////our centres*/
  #ourCentres {
    grid-template-columns: 100vw;
    grid-template-rows: 15vh 35vh;
  }
  #ourCentresImgs {
    grid-template-columns: repeat(4, 24vw);
    grid-template-rows: repeat(3, 10vh);
  }
  #textBottomLeft h1 {font-size: 1.5rem;}
  #textTopRight h1 {font-size: 1.2rem;}
  
  /*////////////////////////////////////////programmes*/
  #programmes {grid-template-rows: 30vh 5vh;}
  #halfCircle {height: 30vh;}
  #programmeCards {
    top: -20vh;
    gap: 0.25rem;
  }
  #programmeCards h4 {font-size: 0.8rem;}
  #programmeCards h5, h6 {font-size: 0.5rem;}
  #programmeCards img {height: 10vh;}
  #programmeCards .MuiCard-root {height: 22vh;}
  #programmeCards .MuiCardContent-root {
    padding: 0.25rem;
    height: 12vh;
  }
  #programmeCards a {
    position: absolute;
    bottom: 0.5rem;
  }
  
  /*////////////////////////////////////////contact us*/
  #contactUs {grid-template-rows: repeat(5, 15vh) 5vh;}
  #contactUs h1 {font-size: 3rem;}
  #contactUsForm {
    max-width: 50vw;
    height: 45vh;
  }
  #contactUsForm input, textarea {max-width: 25vw;}
  #contactUsForm textarea {height: 10vh;}
  #contactUsForm form {
    padding-right: 1rem;
    padding-top: 1rem;
  }
  #contactUsImg img {
    max-width: 50vw;
    height: 40vh;
    border-radius: 0.5rem;
  }

  /*////////////////////////////////////////addresses*/
  #addresses p:nth-child(1) {font-size: 1.1rem;}
  #addresses p:nth-child(n+2) {font-size: 0.6rem;}
  #addresses div {padding: 0.25rem;}
  
  /*////////////////////////////////////////franchise*/
  #franchise h6 {font-size: 0.8rem;}

  /*////////////////////////////////////////footer*/
  footer p {font-size: 0.7rem;}
  #copyrightIcon {font-size: small;}
}



:root {
  /* colors */
  --brown: #CC704B;
  --creme: #FBF2CF;
  --dark: #2C3639;
  --green: #87CEC6;
  --grey: #2C3333;
  --red: #DD5353;
}

.maxWidthMinHeight {
  width: 100vw;
  min-height: 90vh;
}

.fitContainer {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* //////////////////////////////////////////grid */
.theGrid {
  display: grid;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

/* /////////////////////////////////////////bgcolors*/
.bgGreen {background-color: var(--green);}
.bgDark {background-color: var(--grey);}

/* /////////////////////////////////////////flexes*/
.flexGap {
  display: flex;
  gap: 0.25rem;
}
.centerHorizontally {
  display: flex;
  justify-content: center;
}
.centerVertically {
  display: flex;
  align-items: center;
}
.flexVertically {
  display: flex;
  flex-direction: column;
}
.flexHorizontally {
  display: flex;
  flex-direction: row;
}
